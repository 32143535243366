/* eslint react/destructuring-assignment: 0 */
import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import website from "../../config/website"
import { LocaleContext } from "../components/layout"
import SEO from "../components/seo"
import SliceZone from "../components/sliceZone"
import Img from "gatsby-image"
import Categories from "../components/List/categories"

const Post = ({
  data: { prismicPost, posts },
  location,
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const { data } = prismicPost
  let categories = false
  if (data.categories[0].category) {
    categories = data.categories.map(c => c.category.document[0].data.name)
  }
  const keywords = data.stichwoerter
    ? data.stichwoerter.map(sw => sw.stichwort.text)
    : ["Blog", "Artikel", data.title.text]

  return (
    <div className="post-detail">
      <SEO
        title={`${data.title.text}`}
        pathname={location.pathname}
        locale={locale}
        description={
          data.beschreibung.text ? data.beschreibung.text : data.subtitle.text
        }
        node={prismicPost}
        keywords={keywords}
        article
      />
      {data.image.localFile && (
        <div className="post__image">
          <Img
            alt={data.image.alt}
            fluid={data.image.localFile.childImageSharp.fluid}
          />
        </div>
      )}
      <div className="container two-cols">
        <div className="post__meta">
          <div className="post__date">
            <strong>{i18n?.published}</strong> {data.date && data.date}
          </div>{" "}
          <strong>{i18n?.categories}</strong>{" "}
          {categories && <Categories categories={categories} />}
        </div>
        <div className="post__content">
          <h1>{data.title.text}</h1>
          <h3>{data.subtitle.text}</h3>

          {data.body && <SliceZone allSlices={data.body} />}
        </div>
      </div>
    </div>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!, $locale: String!) {
    prismicPost(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        subtitle {
          text
        }
        teaser {
          html
        }
        beschreibung {
          text
        }
        stichwoerter {
          stichwort {
            text
          }
        }
        image {
          alt
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        date(formatString: "DD.MM.YYYY")
        categories {
          category {
            document {
              data {
                name
              }
            }
          }
        }
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
        }
      }
    }
  }
`

// MAYBE LOAD RELATED DATA OR FURTHER POSTS
/*
posts: allPrismicPost(limit: 2, sort: { fields: [data___date], order: DESC }, filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
*/

/*
... on PrismicPostBodyCodeBlock {
            slice_type
            id
            primary {
              code_block {
                html
              }
            }
          }
          ... on PrismicPostBodyQuote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          */
