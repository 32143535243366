import React from "react"
import PropTypes from "prop-types"

const Quote = ({ input }) => (
  <div>
    <div dangerouslySetInnerHTML={{ __html: input.primary.quote.html }} />
  </div>
)

export default Quote

Quote.propTypes = {
  input: PropTypes.object.isRequired,
}
